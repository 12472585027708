<template>
  <div class="container">
    <div class="rules">
      <nav class="rules-nav">
        <div class="rules-nav__wrapper">
          <ul v-scroll-spy-active="{class: 'active'}" v-scroll-spy-link="{selector: 'li'}">
            <li>Правила</li>
            <li>Требования</li>
          </ul>
        </div>
      </nav>
      <ol class="rules-content" v-scroll-spy="{data: 'section', offset: 126}">
        <div class="rules-block">
          <h2>Правила</h2>
          <p v-html="TOURNAMENT.attributes.rules"></p>
        </div>
        <div class="rules-block">
          <h2>Требования</h2>
          <p v-html="TOURNAMENT.attributes.regulations"></p>
        </div>
      </ol>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "tournamentRules",
  computed: {
    ...mapGetters([
        'TOURNAMENT'
    ])
  }
}
</script>

<style scoped>

</style>